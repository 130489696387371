import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { GetCountriesService } from 'src/utility/getCountries';
import { Router } from '@angular/router';
import { EncryptedCookieStorageService } from 'src/utility/services/marketplace/encrypted-cookie-storage.service';
import { Contract } from 'src/utility/Storage/contract';

@Component({
    selector: 'app-select-country-page',
    templateUrl: './select-country-page.component.html',
    styleUrls: ['./select-country-page.component.scss'],
})
export class SelectCountryPageComponent implements OnInit {
    countries: any = [];

    constructor(
        private getCountriesService: GetCountriesService,
        private router: Router,
        private encryptedCookieStorageService: EncryptedCookieStorageService,
        private getCountryData: GetCountriesService
    ) {
        this.countries = this.getCountriesService.getCountries();
    }

    ngOnInit() {}

    selectCountry(item) {
        let expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + 365);
        this.encryptedCookieStorageService.save(
            Contract.countrySelected,
            JSON.stringify(item),
            expirationDate,
            '.forzadelivery.com'
        );
        this.getCountryData.saveData();
        this.router.navigateByUrl('/' + item.value.toUpperCase());
    }
}
