import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthUserService } from 'src/utility/services/auth/auth-user.service';
import { ConnectionApiService } from 'src/utility/services/connection-api.service';
import { SecureSimpleStorage } from 'src/utility/Storage/secure-simple-storage';
import { SweetAlert } from 'src/utility/sweetAlert';
import { Constants, environment } from 'src/environments/environment';
import { FormValidators } from 'src/utility/form/form.validators';
import { Contract } from 'src/utility/Storage/contract';
import { Router } from '@angular/router';
import { AppComponent } from 'src/app';
import { MenuService } from 'src/app/core/menu/menu.service';
import { getCookie, setCookie } from 'src/utility/Storage/cookies';
import { SaveLocalCartService } from 'src/utility/functions/save-local-cart.service';
import { GetCountriesService } from 'src/utility/getCountries';
import { EncryptedCookieStorageService } from 'src/utility/services/marketplace/encrypted-cookie-storage.service';

@Component({
    selector: 'app-login-internal',
    templateUrl: './login-internal.component.html',
    styleUrls: ['./login-internal.component.scss'],
    host: {
        '(window:resize)': 'getWindowWidth()',
    },
})
export class LoginInternalComponent implements OnInit {
    user: FormGroup;
    token: string;
    data2: any;
    isDataValid: boolean;
    isLoading: boolean;
    version;
    coverPage: string;
    userSaved: boolean = false;

    constructor(
        private authService: AuthUserService,
        private secureStorage: SecureSimpleStorage,
        private sweet: SweetAlert,
        private cnxAPIServ: ConnectionApiService,
        private router: Router,
        private menuService: MenuService,
        private saveLocalCartService: SaveLocalCartService,
        private getCountryData: GetCountriesService,
        private encryptedCookieStorageService: EncryptedCookieStorageService
    ) {
        this.authService.getImages().then((response) => {
            this.coverPage = Constants.api.toString();
            this.coverPage = this.coverPage.replace(
                'fd/Home.aspx/API',
                response.ObjectValue[2].Value
            );
        });
        this.SessionOnn();
        this.user = new FormGroup({
            code: new FormControl('', [
                Validators.required,
                Validators.maxLength(13),
                FormValidators.matchEntero(),
            ]),
            name: new FormControl('', [
                Validators.required,
                Validators.maxLength(200),
                //Validators.pattern("([a-z]|[A-Z]|[0-9]|\\.|-|_)+@(([a-z]|[A-Z]|[0-9]|-|_)+\\.)+([a-z]|[A-Z]|[0-9]|-|_)+"),
                FormValidators.mailDomain(),
            ]),
            password: new FormControl('', [
                Validators.required,
                Validators.minLength(1),
                Validators.maxLength(200),
            ]),
            rememberUser: new FormControl(false, []),
        });
    }

    SessionOnn() {
        this.secureStorage.get(Contract.user).subscribe(
            (data) => {
                //parseamos la data.
                this.data2 = JSON.parse(data);
                this.router.navigateByUrl('/GT');
            },
            (error) => {
                this.router.navigateByUrl('/inicio-sesion-interno');
            }
        );
    }

    async ngOnInit() {
        this.isDataValid = false;
        this.isLoading = false;
        this.version = environment.version;
        this.user.statusChanges.subscribe((data) => {
            this.isDataValid = data === 'VALID';
        });
        this.secureStorage.get(Contract.userTelemercadeo).subscribe(
            (data) => {
                let user: any = JSON.parse(data);
                this.user.controls.code.setValue(user.UserCode);
                this.user.controls.name.setValue(user.UserName);
                this.user.controls.password.setValue(user.Password);
                this.user.controls.rememberUser.setValue(true);
                this.userSaved = true;
            },
            (error) => {}
        );
    }

    ngAfterViewInit(): void {}

    corporateLogin() {
        if (this.user.valid) {
            var codeNumb: Number = +this.user.controls.code.value;
            this.isLoading = true;
            let body = {
                path: 'MarketPlace/GetInternalLoginMarketPlace',
                data: {
                    Method: 'GetInternalLoginMarketPlace',
                    Params: {
                        UserCode: codeNumb,
                        UserName: this.user.controls.name.value,
                        Password: this.user.controls.password.value,
                        SystemName: 'Hermes web operaciones',
                    },
                },
            };
            this.cnxAPIServ.postData(body).subscribe(
                async (response) => {
                    environment.rol = '';
                    if (response.ObjectValue[0].IdResult == 200) {
                        if (this.user.controls.rememberUser.value) {
                            this.secureStorage
                                .save(
                                    Contract.userTelemercadeo,
                                    JSON.stringify(body.data.Params)
                                )
                                .subscribe();
                        } else {
                            if (this.userSaved) {
                                this.secureStorage
                                    .remove([Contract.userTelemercadeo])
                                    .subscribe();
                            }
                        }
                        //Response OK
                        this.secureStorage
                            .save(Contract.updateVersion, false)
                            .subscribe();

                        this.cnxAPIServ.login(response).subscribe();
                        this.authService.setUserData(response);

                        let rolname =
                            response.ObjectValue[0].Accounts[0].RolName;
                        environment.rol =
                            response.ObjectValue[0].Accounts[0].TacName;
                        if (rolname !== undefined) {
                            environment.rol = rolname;
                        }
                        this.menuService.clearMenu();
                        this.menuService.clearSecondaryMenu();
                        this.menuService.addMenu(
                            response.ObjectValue[0].Modules
                        );
                        this.setTelemercadoCountryData(
                            response.ObjectValue[0].Profile[1].IdCountry
                        );
                        this.getCountryData.saveData();
                        await this.saveLocalCartService.saveLocalCart();
                        this.router.navigateByUrl(
                            '/' + response.ObjectValue[0].Profile[1].IdCountry
                        );
                        this.authService.updateVersion();
                        this.isLoading = false;
                    } else if (response.ObjectValue[0].IdResult == 400) {
                        this.sweet.errorCriticalAlert(
                            'Error al iniciar sesión',
                            'Los datos ingresados no son correctos'
                        );
                        this.isLoading = false;
                    } else {
                    }
                },
                (error) => {
                    this.sweet.errorCriticalAlert(
                        'Error al iniciar sesión',
                        error.Message ? error.Message : error
                    );
                    this.isLoading = false;
                }
            );
        } else {
            this.sweet.errorCriticalAlert(
                'Error al iniciar sesión',
                'Por favor completar el formulario'
            );
        }
    }

    setTelemercadoCountryData(countryUser) {
        let countrySelect: {
            countryName: any;
            value: any;
            icon: any;
            prefixPhone: any;
            currency: any;
        };

        if (countryUser == 'GT') {
            countrySelect = {
                countryName: 'Guatemala',
                icon: 'icon-gt',
                value: 'GT',
                prefixPhone: '+502',
                currency: 'GTQ',
            };
        } else {
            countrySelect = {
                countryName: 'Honduras',
                icon: 'icon-hn',
                value: 'HN',
                prefixPhone: '+504',
                currency: 'HNL',
            };
        }

        let expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + 365);
        this.encryptedCookieStorageService.save(
            Contract.TelemercadeoCountrySelected,
            JSON.stringify(countrySelect),
            expirationDate,
            '.forzadelivery.com'
        );
    }

    getWindowWidth() {
        let img = document.getElementById('container-img-login');
        let newWidth = `calc(100% - ${img.clientWidth}px)`;
        document.documentElement.style.setProperty(
            '--width-inputs-login',
            newWidth
        );
    }
}
