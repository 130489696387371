<!-- START Top Navbar-->
<nav class="navbar topnavbar" role="navigation">
    <!-- START navbar header-->
    <div
        class="navbar-header p-3 d-none d-sm-flex order-1"
        id="logoHeaderId"
        [ngClass]="{ 'navbar-header-logo': showBackBtn }">
        <a
            class="navbar-brand p-0 m-0"
            (click)="changeRoute('/' + this.countrySelect.value)">
            <div class="brand-logo p-0 m-0">
                <img
                    class="img-fluid img-logo m-0"
                    src="assets/images/logoForza.png"
                    alt="App Logo" />
            </div>
            <div class="brand-logo-collapsed">
                <img
                    class="img-fluid"
                    src="assets/img/logo-single.png"
                    alt="App Logo" />
            </div>
        </a>
    </div>
    <!-- END navbar header-->
    <!-- START Left navbar-->
    <div
        class="h-100 container-search px-1 px-sm-3 order-3 order-sm-2 d-none d-sm-flex"
        id="searchHeaderId">
        <div class="row w-100">
            <div class="col-10">
                <div
                    class="sub-container w-100 h-100 d-flex align-items-center justify-content-center">
                    <app-product-finder
                        [countrySelect]="countrySelect"
                        style="width: 100%"
                        (closeModal)="
                            closeModalSearchProduct($event)
                        "></app-product-finder>
                </div>
            </div>
            <div class="col-2">
                <div class="w-100 h-100">
                    <div class="dropdown">
                        <button
                            class="d-flex justify-content-between btn-country"
                            style="width: 90%"
                            data-toggle="dropdown"
                            [disabled]="enableCountry"
                            aria-expanded="false">
                            <em
                                [class]="countrySelect?.icon"
                                style="width: 30px !important"></em>
                            <span class="ml-4 text text-option-country">
                                {{ countrySelect?.countryName }}
                            </span>
                            <img
                                src="/assets/icons/arrow-down.svg"
                                style="width: 24px" />
                        </button>
                        <div
                            class="dropdown-menu w-100 py-0 my-0 drop-country"
                            *ngIf="countries.length > 0">
                            <div *ngFor="let item of countries">
                                <div
                                    class="w-100 dropdown-item cursor-pointer country-item d-flex align-items-center"
                                    (click)="selectCountry(item)">
                                    <div class="w-100">
                                        <em
                                            [class]="item?.icon"
                                            style="width: 21px !important"></em>
                                        <span
                                            class="ml-md-4 text text-option-country">
                                            {{ item.countryName }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- END Left navbar-->

    <!-- START Right Navbar-->
    <ul
        class="navbar-nav flex-row order-2 order-sm-3 ml-auto"
        id="cartHeaderId">
        <!-- Fullscreen (only desktops)-->
        <li class="nav-item px-3 d-flex d-sm-none">
            <div class="h-100 d-flex justify-content-center align-items-center">
                <div class="dropdown">
                    <button
                        class="d-flex justify-content-between btn-country"
                        style="width: 90%"
                        data-toggle="dropdown"
                        [disabled]="enableCountry"
                        aria-expanded="false">
                        <em
                            [class]="countrySelect?.icon"
                            style="width: 30px !important"></em>
                        <img
                            src="/assets/icons/arrow-down.svg"
                            style="width: 24px" />
                    </button>
                    <div
                        class="dropdown-menu w-100 py-0 my-0 drop-country"
                        *ngIf="countries.length > 0">
                        <div *ngFor="let item of countries">
                            <div
                                class="w-100 dropdown-item cursor-pointer country-item d-flex align-items-center"
                                (click)="selectCountry(item)">
                                <div class="w-100">
                                    <em
                                        [class]="item?.icon"
                                        style="width: 21px !important"></em>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </li>
        <li class="nav-item px-3 d-flex d-sm-none">
            <div class="h-100 d-flex justify-content-center align-items-center">
                <em
                    class="fa fa-search fa-2x cursor-pointer"
                    style="color: #545454"
                    (click)="openModalSearch()"></em>
            </div>
        </li>
        <li
            class="nav-item px-3"
            joyrideStep="step2"
            appJrAnchor="cart-icon"
            id="step2"
            [title]="AllArraySteps[2].header"
            [text]="AllArraySteps[2].text">
            <a class="nav-link h-100 d-flex align-items-center" #fsbutton>
                <div
                    class="row text-center cursor-pointer icon-navbar"
                    (click)="toggleOffsidebar(); $event.stopPropagation()"
                    style="position: relative">
                    <em class="fa icon-cart fa-2x d-block" style="width: 30px">
                    </em>
                    <span class="numberIcon">{{
                        numGuide > 99 ? '99+' : numGuide
                    }}</span>
                </div>
            </a>
        </li>
        <!-- START Alert menu FOR INDIVIDUAL CUSTOMER-->
        <li
            joyrideStep="step3"
            appJrAnchor="login-icon"
            id="step3"
            [title]="AllArraySteps[3].header"
            [text]="AllArraySteps[3].text"
            class="nav-item dropdown dropdown-list cursor-pointer px-3"
            dropdown
            *ngFor="let menuItem of menuItems; let pos = index">
            <a
                class="nav-link dropdown-toggle dropdown-toggle-nocaret"
                dropdownToggle>
                <div class="row text-center icon-navbar">
                    <em
                        *ngIf="imageProfile == ''"
                        class="fa icon-person fa-2x d-block"
                        style="width: 30px"></em>
                    <img
                        class="img-profile"
                        *ngIf="imageProfile != ''"
                        [src]="imageProfile"
                        alt="" />
                    <span class="text-dark d-none d-md-block mt-2 ml-1"
                        >&nbsp;{{ fullName }}</span
                    >
                </div>
            </a>
            <!-- START Dropdown menu-->
            <div
                *dropdownMenu
                class="dropdown-menu dropdown-menu-right animated">
                <div class="dropdown-item">
                    <!-- START list group-->
                    <div class="list-group">
                        <div
                            class="list-group-item list-group-item-action hoverList"
                            (click)="changeRoute('/terminos-condiciones')">
                            <div class="media">
                                <div class="align-self-start mr-2 ml-1">
                                    <em class="bi-file-text-fill"></em>
                                </div>
                                <div class="media-body">
                                    <a
                                        class="dropdown-item"
                                        title="Términos y Condiciones">
                                        <span>Términos y Condiciones</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="list-group">
                        <div
                            class="list-group-item list-group-item-action hoverList"
                            (click)="changeRoute('/mis-transacciones')">
                            <div class="media">
                                <div class="align-self-start mr-2 ml-1">
                                    <em class="bi bi-receipt"></em>
                                </div>
                                <div class="media-body">
                                    <a
                                        class="dropdown-item"
                                        title="Mis transacciones">
                                        <span>Mis transacciones</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="list-group">
                        <div
                            class="list-group-item list-group-item-action hoverList"
                            (click)="authService.setLogout()">
                            <div *ngIf="pos == 0" class="media">
                                <div class="align-self-start mr-2 ml-1">
                                    <em class="bi bi-box-arrow-in-left"></em>
                                </div>
                                <div class="media-body">
                                    <a
                                        class="dropdown-item"
                                        title="Cerrar sesión">
                                        <span>Cerrar sesión</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- END list group-->
                </div>
            </div>
            <!-- END Dropdown menu-->
        </li>

        <!-- START Alert menu FOR TELEMERCADEO-->
        <li
            joyrideStep="step4"
            appJrAnchor="login-icon"
            id="step4"
            [title]="AllArraySteps[3].header"
            [text]="AllArraySteps[3].text"
            class="nav-item dropdown dropdown-list cursor-pointer px-3"
            dropdown
            *ngIf="userRol == 'Ventas telemercadeo'">
            <a
                class="nav-link dropdown-toggle dropdown-toggle-nocaret"
                dropdownToggle>
                <div class="row text-center icon-navbar">
                    <em
                        class="fa icon-person fa-2x d-block"
                        style="width: 30px"></em>
                    <span class="text-dark d-none d-md-block mt-2 ml-1"
                        >&nbsp;{{ fullName }}</span
                    >
                </div>
            </a>
            <!-- START Dropdown menu-->
            <div
                *dropdownMenu
                class="dropdown-menu dropdown-menu-right animated">
                <div class="dropdown-item">
                    <!-- START list group-->
                    <div class="list-group">
                        <div
                            class="list-group-item list-group-item-action hoverList"
                            (click)="authService.setLogout()">
                            <div class="media">
                                <div class="align-self-start mr-2 ml-1">
                                    <em class="bi bi-box-arrow-in-left"></em>
                                </div>
                                <div class="media-body">
                                    <a
                                        class="dropdown-item"
                                        title="Cerrar sesión">
                                        <span>Cerrar sesión</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- END list group-->
                </div>
            </div>
            <!-- END Dropdown menu-->
        </li>
        <li
            joyrideStep="step5"
            appJrAnchor="login-icon"
            id="step5"
            [title]="AllArraySteps[3].header"
            [text]="AllArraySteps[3].text"
            class="nav-item dropdown dropdown-list cursor-pointer px-3"
            dropdown
            *ngIf="!userLogged">
            <a
                class="nav-link dropdown-toggle dropdown-toggle-nocaret"
                dropdownToggle>
                <div class="row text-center icon-navbar">
                    <em
                        class="fa icon-person fa-2x d-block"
                        style="width: 30px"></em>
                    <span class="text-dark d-block mt-2 ml-0 ml-md-1"
                        >&nbsp;Iniciar <br class="d-flex d-md-none" />
                        Sesión</span
                    >
                </div>
            </a>

            <!-- START Dropdown menu-->
            <div
                *dropdownMenu
                class="dropdown-menu dropdown-menu-right animated">
                <div class="dropdown-item">
                    <!-- START list group-->
                    <div class="list-group">
                        <div
                            class="list-group-item list-group-item-action hoverList"
                            (click)="toLogin()">
                            <div class="media d-flex">
                                <div class="align-self-start mr-2 ml-1">
                                    <em
                                        class="fa icon-person fa-1x d-block mt-1"
                                        style="width: 15px; height: 16px"></em>
                                </div>
                                <div class="media-body">
                                    <a
                                        class="dropdown-item"
                                        title="Iniciar Sesión">
                                        <span>Iniciar Sesión</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="list-group">
                        <div
                            class="list-group-item list-group-item-action hoverList"
                            (click)="toTermsAndConditions()">
                            <div class="media">
                                <div class="align-self-start mr-2 ml-1">
                                    <em class="bi-file-text-fill"></em>
                                </div>
                                <div class="media-body">
                                    <a
                                        class="dropdown-item"
                                        title="Términos y Condiciones">
                                        <span>Términos y Condiciones</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- END list group-->
                </div>
            </div>
            <!-- END Dropdown menu-->
        </li>
    </ul>
    <div
        class="w-100 container-back-btn order-4"
        [ngClass]="{
            'ml-3 mb-3': showBackBtn,
            'mt-1 mb-2 h-sm-auto': showRoutes
        }"
        *ngIf="showBackBtn || showRoutes"
        id="backBtnHeaderId">
        <div
            *ngIf="showBackBtn"
            class="px-4 pb-2 w-100 d-flex align-content-center justify-content-start container-btn-back-2">
            <button
                class="btn btn-warning px-1 d-flex align-items-center justify-content-center"
                tooltip="Ir al inicio"
                placement="right"
                (click)="back()">
                <i class="mt-1 bi bi-arrow-left-circle fa-2x"></i>
            </button>
        </div>
        <div class="container-route" *ngIf="showRoutes">
            <i
                class="bi bi-house-fill cursor-pointer"
                tooltip="Ir al inicio"
                placement="right"
                (click)="changeRoute('/' + this.countrySelect.value)"></i>
            /
            {{ categorySelected }}
        </div>
    </div>
</nav>
<!-- END Top Navbar-->

<app-modal
    #modalSearchProduct
    [idModal]="'modalSearchProduct'"
    fatherClass="overflow-hidden">
    <div class="w-100 h-auto mt-4">
        <app-product-finder
            [isModal]="true"
            (closeModal)="closeModalSearchProduct($event)"></app-product-finder>
    </div>
</app-modal>
