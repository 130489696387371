import { Injectable } from '@angular/core';
import { SecureSimpleStorage } from '../Storage/secure-simple-storage';
import { Contract } from '../Storage/contract';
import { environment } from 'src/environments/environment';
import { ConnectionApiService } from '../services/connection-api.service';

@Injectable({
    providedIn: 'root',
})
export class SaveLocalCartService {
    constructor(
        private secureStorage: SecureSimpleStorage,
        private connectionApi: ConnectionApiService
    ) {}

    async saveLocalCart() {
        let countrySelect = environment.multiCountryData[0].countrySelect;
        let localCart: any = [];
        try {
            let item = await this.secureStorage
                .get(Contract.localCart)
                .toPromise();
            localCart = JSON.parse(item);
        } catch (e) {}
        if (localCart.length == 0) {
            return;
        }
        if (environment.rol == 'Ventas telemercadeo') {
            try {
                let countrySelect =
                    environment?.TelemercadeoCountryData[0]?.countrySelect;
                let currency = countrySelect?.value == 'GT' ? 'Q.' : 'L.';
                if (localCart[0].CurrencySymbol != currency) {
                    this.secureStorage
                        .remove(['productRoutes', 'localCart'])
                        .subscribe();
                    return;
                }
            } catch (error) {}
        }
        let cart: any = [];
        for (let iterator of localCart) {
            cart.push({
                IdSalePackage: iterator.ProductId,
                TypeSalePackage:
                    iterator.CatProductName == 'Club Forza'
                        ? 'MEMBERSHIP'
                        : iterator.CatProductDescription,
            });
        }
        let userString = await this.secureStorage
            .get(Contract.user)
            .toPromise();
        const user = JSON.parse(userString);
        let body = {
            path: 'Marketplace/AddMarketplaceProductstoCart',
            data: {
                Method: 'AddMarketplaceProductstoCart',
                Params: {
                    IdAccount:
                        user.ObjectValue[0]?.Accounts[0]?.RolName !=
                        'Ventas telemercadeo'
                            ? Number(user.ObjectValue[0].Accounts[0]?.IdAccount)
                            : Number(user.ObjectValue[0].Accounts[0]?.IdUser),
                    Token: user.ObjectValue[0]?.Token,
                    Product: cart,
                    CountryId: countrySelect.value,
                    IsUserTeleMarketing:
                        user.ObjectValue[0]?.Accounts[0]?.RolName !=
                        'Ventas telemercadeo'
                            ? 0
                            : 1,
                },
            },
        };

        try {
            const response = await this.connectionApi
                .postData(body)
                .toPromise();
            if (!response.ObjectValue) {
                return;
            }
            this.secureStorage.remove([Contract.localCart]).subscribe();
        } catch (error) {}
    }
}
