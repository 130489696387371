import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Contract } from 'src/utility/Storage/contract';
import { SecureSimpleStorage } from 'src/utility/Storage/secure-simple-storage';
import { ConnectionApiService } from 'src/utility/services/connection-api.service';
import { ComponentCommunicationService } from 'src/utility/services/marketplace/component-communication.service';
import { EncryptedCookieStorageService } from 'src/utility/services/marketplace/encrypted-cookie-storage.service';
import { SweetAlert } from 'src/utility/sweetAlert';

@Component({
    selector: 'app-product-finder',
    templateUrl: './product-finder.component.html',
    styleUrls: ['./product-finder.component.scss'],
    host: {
        '(window:resize)': 'getWindowWidth()',
    },
})
export class ProductFinderComponent implements OnInit, OnDestroy {
    @Input() isModal: boolean = false;
    @Input()
    countrySelect: any;
    lastWidth: number = 0;
    isOpenMobile: boolean = false;
    text: string = '';
    searchResult: boolean = false;
    productItems: any[];
    searchResultEmpty: boolean = false;
    wordForSearch: any;
    isSubsOpen: Array<any> = [];
    products: any[] = [];
    @Output() closeModal: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        private connectionApi: ConnectionApiService,
        private sweetAlert: SweetAlert,
        private router: Router,
        private componentCommunicationService: ComponentCommunicationService,
        private secureStorage: SecureSimpleStorage,
        private encryptedCookieStorageService: EncryptedCookieStorageService
    ) {}

    async ngOnInit() {
        this.lastWidth = document.body?.clientWidth;
        this.countrySelect = environment.multiCountryData[0].countrySelect;
        if (environment.rol == 'Ventas telemercadeo') {
            this.countrySelect =
                environment.TelemercadeoCountryData[0].countrySelect;
        }
        await this.getCategories();
        await this.getProducts();
    }

    async getCategories() {
        const body = {
            path: 'MarketPlace/LoadProductCategory',
            data: {
                Method: 'LoadProductCategory',
                CountryId: this.countrySelect.value,
            },
        };
        try {
            let response = await this.connectionApi.postData(body).toPromise();
            if (response.StatusCode != 200 || !response.ObjectValue) {
                return;
            }
            this.products.push({
                Name: 'Categorias',
                Elements: response.ObjectValue,
                order: 0,
            });
        } catch (error) {
            console.error(error);
        }
    }

    async getProducts() {
        const body = {
            path: 'MarketPlace/LoadingElementsandSubelements',
            data: {
                Method: 'LoadingElementsandSubelements',
                Params: {
                    CountryId: this.countrySelect.value,
                },
            },
        };
        try {
            let response = await this.connectionApi.postData(body).toPromise();
            if (!response.ObjectValue || response.StatusCode != 200) {
                return;
            }
            let products = {
                Name: 'Productos',
                Elements: [],
                order: 1,
            };
            let productRoutes: any = [];
            let indexPrincipal = 0;
            for (let tag of response.ObjectValue) {
                indexPrincipal++;
                let indexSecundary = 0;
                for (let product of tag.Products) {
                    indexSecundary++;
                    product = { ...product, ...{ TagName: tag.TagName } };
                    let idx = products.Elements.findIndex(
                        (item) =>
                            item.IdCatProduct == product.IdCatProduct &&
                            item.Name == product.Name
                    );
                    if (idx >= 0) {
                        products.Elements[idx].TagName =
                            products?.Elements[idx]?.TagName +
                            ' ' +
                            product?.TagName;
                    } else {
                        products.Elements.push(product);
                        productRoutes.push({
                            Name: product.Name,
                            NameWithHyphen: product.Name.replace(/\s+/g, '-')
                                .normalize('NFD')
                                .replace(/[\u0300-\u036f]/g, '')
                                .toLowerCase(),
                            IdCatProduct: product.IdCatProduct,
                        });
                    }
                    if (
                        indexPrincipal == response.ObjectValue.length &&
                        indexSecundary == tag.Products.length &&
                        !this.isModal
                    ) {
                        this.secureStorage
                            .save(
                                Contract.productRoutes,
                                JSON.stringify(productRoutes)
                            )
                            .subscribe();
                    }
                }
            }
            this.products.push(products);
        } catch (error) {
            console.error(error);
        }
    }

    ngOnDestroy(): void {}

    addFix() {
        this.searchResult = true;
    }

    removeFix() {
        this.searchResult = false;
        this.text = '';
    }

    searchTerm(text: any) {
        this.wordForSearch = text;
        text ? this.addFix() : this.removeFix();
        text = this.removeAccents(text?.toLowerCase());
        this.productItems = this.searchElement(text);
        this.checkSearchResultEmpty(this.productItems);
        this.getWindowWidth();
    }

    searchElement(text) {
        // Filtrar el array para encontrar coincidencias en cualquier object
        var results = this.products?.filter(function (item) {
            // Buscar en el parámetro "Name"
            if (
                item?.Name?.toLowerCase()
                    ?.normalize('NFD')
                    ?.replace(/[\u0300-\u036f]/g, '')
                    ?.includes(text)
            ) {
                return true;
            }

            // Buscar en cualquier object
            return item?.Elements?.some(function (element) {
                return Object.values(element).some(function (object: any) {
                    if (Array.isArray(object)) {
                        return object?.some(function (subObject) {
                            return Object.values(subObject).some(function (
                                item: any
                            ) {
                                return item
                                    ?.toString()
                                    ?.toLowerCase()
                                    ?.normalize('NFD')
                                    ?.replace(/[\u0300-\u036f]/g, '')
                                    ?.includes(text);
                            });
                        });
                    }
                    return object
                        ?.toString()
                        ?.toLowerCase()
                        ?.normalize('NFD')
                        ?.replace(/[\u0300-\u036f]/g, '')
                        ?.includes(text);
                });
            });
        });

        // Devolver un nuevo array con los results encontrados
        return results?.map(function (result) {
            return {
                Name: result.Name,
                Elements: result?.Name?.toLowerCase()
                    ?.normalize('NFD')
                    ?.replace(/[\u0300-\u036f]/g, '')
                    ?.includes(text)
                    ? result?.Elements
                    : result?.Elements?.filter(function (element) {
                          return Object.values(element).some(function (
                              object: any
                          ) {
                              if (Array.isArray(object)) {
                                  return object?.some(function (subObject) {
                                      return Object.values(subObject).some(
                                          function (item: any) {
                                              return item
                                                  ?.toString()
                                                  ?.toLowerCase()
                                                  ?.normalize('NFD')
                                                  ?.replace(
                                                      /[\u0300-\u036f]/g,
                                                      ''
                                                  )
                                                  ?.includes(text);
                                          }
                                      );
                                  });
                              }
                              return object
                                  ?.toString()
                                  ?.toLowerCase()
                                  ?.normalize('NFD')
                                  ?.replace(/[\u0300-\u036f]/g, '')
                                  ?.includes(text);
                          });
                      }),
                order: result?.order,
            };
        });
    }

    removeAccents(value) {
        return value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    }

    checkSearchResultEmpty(items) {
        if (!items.length) this.searchResultEmpty = true;
        else this.searchResultEmpty = false;
    }

    clear() {
        this.text = '';
        this.searchResultEmpty = false;
    }

    async navigation(element, subElement) {
        if (element.Name == 'Categorias') {
            this.clear();
            this.closeModal.emit(subElement.ProductCategoryName);
            this.componentCommunicationService.sendResponseForProductSearch(
                'category',
                subElement
            );
            this.router.navigateByUrl('/resultado-busqueda');
        } else if (element.Name == 'Productos') {
            this.viewDetail(subElement?.IdCatProduct, subElement?.Name);
        }
    }

    async viewDetail(idProduct: any, name: any) {
        this.clear();
        this.closeModal.emit('');
        this.router.navigateByUrl(
            `${this.countrySelect.value}/detalle-producto/${name
                .replace(/\s+/g, '-')
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .toLowerCase()}`
        );
    }

    getWindowWidth() {
        let width = document.body?.clientWidth;
        let height = document.body?.clientHeight;
        let containerResults = document.getElementById('search-outer');
        if (height < 500 && containerResults) {
            containerResults.setAttribute(
                'style',
                'max-height: calc(100vh - 140px) !important'
            );
        } else if (containerResults) {
            containerResults.setAttribute(
                'style',
                'max-height: 350px !important'
            );
        }
        if (width == this.lastWidth) {
            return;
        }
        this.lastWidth = width;
        this.clear();
    }

    showAllResults() {
        if (this.text == '' || !this.text || this.searchResultEmpty) {
            return;
        }
        this.clear();
        this.closeModal.emit('Búsqueda');
        this.componentCommunicationService.sendResponseForProductSearch(
            'all',
            this.productItems
        );
        this.router.navigateByUrl('/resultado-busqueda');
    }
}
