<div
    class="secundary-container d-block d-md-flex"
    style="background-color: #f6f7f9">
    <div
        class="h-auto m-auto align-content-center text-center order-1 order-md-2 container-options px-5 pt-5 pb-10 pb-md-5">
        <div class="title">Bienvenido a Forza Delivery</div>
        <img
            class="img-fluid img-logo m-0 py-2"
            src="assets/images/logoForza.png"
            style="width: 350px"
            alt="App Logo" />
        <div class="title">¡Ahora en Honduras y Guatemala!</div>
        <div class="text mt-3">Para comenzar, por favor selecciona tu país</div>
        <div *ngFor="let item of countries">
            <div
                class="d-flex align-items-center justify-content-init container-icon mt-3 py-4 mb-5 cursor-pointer"
                (click)="selectCountry(item)">
                <em [class]="item.icon"></em>
                <span class="ml-2 text">{{ item.countryName }}</span>
            </div>
        </div>
    </div>
    <!-- <div class="img-container-select-country h-100 order-2 order-md-1 container-img mt-5 mt-md-0">
    </div> -->

    <div class="container-img mt-10 mt-md-0">
        <div id="carouselExampleInterval" class="carousel slide carousel-fade h-100" data-bs-ride="carousel">
            <div class="carousel-inner h-100">
            <div class="carousel-item active h-100 image-container" data-bs-interval="7000">
                <picture>
                <source media="(max-width: 480px)" srcset="./assets/images/express-country-2-480-675.png">
                <source media="(max-width: 1024px)" srcset="./assets/images/express-country-2-480-900.png">
                <source media="(max-width: 1266px)" srcset="./assets/images/express-country-2-1266-936.png">
                <img src="./assets/images/express-country-2.png" class="h-100 w-100" alt="imagen1">
                </picture>
            </div>
            <div class="carousel-item h-100 image-container" data-bs-interval="7000">
                <picture>
                <source media="(max-width: 480px)" srcset="./assets/images/express-country-480-675.png">
                <source media="(max-width: 1024px)" srcset="./assets/images/express-country-480-900.jpg">
                <source media="(max-width: 1266px)" srcset="./assets/images/express-country-1266-936.png">
                <img src="./assets/images/express-country.png" class="h-100 w-100" alt="imagen2">
                </picture>
            </div>
            </div>
        </div>
    </div>
</div>
<footer class="footer text-center">
    <img
        class="img-logo m-0"
        style="width: 200px"
        src="assets/images/logoDarkTarjeta.png"
        alt="App Logo" />
    <p class="text-center mt-3">Todos los derechos reservados. 2024©</p>
</footer>
