import {
    Component,
    HostBinding,
    OnInit,
    AfterViewInit,
    ChangeDetectorRef,
    AfterContentChecked,
    Inject,
    ViewChild,
    HostListener,
} from '@angular/core';
import { ConnectionApiService } from 'src/utility/services/connection-api.service';
import { Contract } from 'src/utility/Storage/contract';
import { SecureSimpleStorage } from 'src/utility/Storage/secure-simple-storage';
import { MenuService } from './core/menu/menu.service';
import { SettingsService } from './core/settings/settings.service';
import { environment } from 'src/environments/environment';
import { AuthUserService } from 'src/utility/services/auth/auth-user.service';
import {
    ActivatedRoute,
    NavigationEnd,
    Router,
    RouterState,
} from '@angular/router';
import { GoogleAnalyticsService } from 'src/utility/services/google-analitycs.service';
import { DOCUMENT } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { ModalComponent } from 'src/components/modal/modal.component';
import { SaveLocalCartService } from 'src/utility/functions/save-local-cart.service';
import { LoginService } from 'src/utility/services/login.service';
import { EncryptedCookieStorageService } from 'src/utility/services/marketplace/encrypted-cookie-storage.service';
import { SweetAlert } from 'src/utility/sweetAlert';
import { GetCountriesService } from 'src/utility/getCountries';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent
    implements OnInit, AfterViewInit, AfterContentChecked
{
    message: string = '';
    user: string = '';
    password: string = '';
    country: string = '';
    modulos: any;
    activePath = '';
    responseLogin: any[];
    cleanLocalStorage: boolean = false;
    @HostBinding('class.layout-fixed') get isFixed() {
        return this.settings.getLayoutSetting('isFixed');
    }
    @HostBinding('class.aside-collapsed') get isCollapsed() {
        return this.settings.getLayoutSetting('isCollapsed');
    }
    @HostBinding('class.layout-boxed') get isBoxed() {
        return this.settings.getLayoutSetting('isBoxed');
    }
    @HostBinding('class.layout-fs') get useFullLayout() {
        return this.settings.getLayoutSetting('useFullLayout');
    }
    @HostBinding('class.hidden-footer') get hiddenFooter() {
        return this.settings.getLayoutSetting('hiddenFooter');
    }
    @HostBinding('class.layout-h') get horizontal() {
        return this.settings.getLayoutSetting('horizontal');
    }
    @HostBinding('class.aside-float') get isFloat() {
        return this.settings.getLayoutSetting('isFloat');
    }
    @HostBinding('class.offsidebar-open') get offsidebarOpen() {
        return this.settings.getLayoutSetting('offsidebarOpen');
    }
    @HostBinding('class.aside-toggled') get asideToggled() {
        return this.settings.getLayoutSetting('asideToggled');
    }
    @HostBinding('class.aside-collapsed-text') get isCollapsedText() {
        return this.settings.getLayoutSetting('isCollapsedText');
    }
    @ViewChild('modalForCookies') modalForCookies: ModalComponent;
    constructor(
        public settings: SettingsService,
        private secureStorage: SecureSimpleStorage,
        private menuService: MenuService,
        private coneccionServices: ConnectionApiService,
        private authService: AuthUserService,
        private router: Router,
        private changeDetector: ChangeDetectorRef,
        private googleAnalyticsService: GoogleAnalyticsService,
        private titleService: Title,
        @Inject(DOCUMENT) private document: Document,
        private sweetAlert: SweetAlert,
        private authentication: LoginService,
        private encryptedCookieStorageService: EncryptedCookieStorageService,
        private saveLocalCartService: SaveLocalCartService,
        private getCountryData: GetCountriesService
    ) {
        //this.callApp();
        //this.authService.updateVersion(this.activePath);
        this.secureStorage.get('Internal').subscribe(
            (x) => {
                this.router.navigate(['inicio-sesion-interno']);
                this.secureStorage.remove(['Internal']).subscribe();
            },
            (z) => {}
        );
        this.secureStorage.get('Afiliados').subscribe(
            (x) => {
                this.router.navigate(['login-affiliate']);
                this.secureStorage.remove(['Afiliados']).subscribe();
            },
            (z) => {}
        );
    }
    @HostListener('window:focus', ['$event'])
    onFocus(event: Event): void {
        setTimeout(() => {
            this.checkCookies();
        }, 1000);
    }
    ngAfterViewInit(): void {
        setTimeout(() => {
            this.secureStorage
                .get(Contract.routeActive)
                .subscribe(async (result) => {
                    try {
                        const paramsString = await this.secureStorage
                            .get(Contract.queryParamsRouteActive)
                            .toPromise();
                        this.secureStorage
                            .remove([Contract.queryParamsRouteActive])
                            .subscribe();
                        const params = JSON.parse(paramsString);
                        this.router.navigate([result], { queryParams: params });
                    } catch (error) {
                        //const url = this.router?.url?.split('/');
                        if (
                            window.location.pathname !=
                                '/inicio-sesion-interno' &&
                            !window.location.pathname.includes(
                                '/detalle-producto'
                            )
                        ) {
                            this.router.navigate([result]);
                        }
                    }
                });
        }, 100);
        this.callMenu();
        setTimeout(() => {
            // this.checkCookies();
        }, 1000);
        let country = this.encryptedCookieStorageService.get(
            Contract.countrySelected
        );
        if (country === null) {
            if (
                window.location.pathname !== '/inicio-sesion-interno' &&
                window.location.pathname.toLowerCase() !== '/hn' &&
                window.location.pathname.toLowerCase() !== '/gt'
            ) {
                this.router.navigate(['bienvenida']);
                this.secureStorage
                    .save(Contract.routeActive, '/bienvenida')
                    .subscribe();
            }
        }

        let countryJson = JSON.parse(country);

        if (
            window.location.pathname.toLowerCase() === '/' ||
            window.location.pathname.toLowerCase() === '' ||
            window.location.pathname.toLowerCase() === '/inicio' ||
            window.location.pathname.toLowerCase() === '/inicio/'
        ) {
            window.location.href = '/' + countryJson.value;
            this.secureStorage
                .save(Contract.routeActive, '/' + countryJson.value)
                .subscribe();
        }
        this.getCountryData.saveData();
    }
    ngAfterContentChecked(): void {
        this.changeDetector.detectChanges();
    }
    async initializeApp() {
        if (environment.apigoogle) {
            const scriptEl = window.document.createElement('script');
            scriptEl.async = true;
            scriptEl.src =
                'https://maps.googleapis.com/maps/api/js?key=' +
                environment.apigoogle +
                '&libraries=places,geometry&language=ES&region=GT';
            window.document.head.appendChild(scriptEl);
        }
        const scriptUrlGA = window.document.createElement('script');
        scriptUrlGA.async = true;
        scriptUrlGA.src = `https://www.googletagmanager.com/gtag/js?id=${environment.googleAnalytics}`;
        window.document.head.appendChild(scriptUrlGA);
        const scriptGA = window.document.createElement('script');
        scriptGA.textContent = `window.dataLayer = window.dataLayer || [];
            function gtag() {
                window.dataLayer.push(arguments);
            }
            gtag('js', new Date());
            gtag('config', '${environment.googleAnalytics}');`;
        window.document.head.appendChild(scriptGA);
    }

    async ngOnInit() {
        await this.initializeApp();
        await this.handleRouteEvents();
        await this.validateCountryCart();
        try {
            if ('serviceWorker' in navigator) {
                navigator?.serviceWorker
                    ?.getRegistrations()
                    ?.then((registrations) => {
                        registrations?.forEach((registration) => {
                            registration?.unregister();
                        });
                    });
            }
        } catch (error) {}
    }

    async validateCountryCart() {
        this.secureStorage.get(Contract.localCart).subscribe(
            (data) => {
                let cartData = JSON.parse(data);
                let country = this.encryptedCookieStorageService.get(
                    Contract.countrySelected
                );
                let countrySelect = JSON.parse(country);
                let currency = countrySelect.value == 'GT' ? 'Q.' : 'L.';
                if (cartData) {
                    if (cartData[0].CurrencySymbol != currency) {
                        this.secureStorage
                            .remove(['productRoutes', 'localCart'])
                            .subscribe();
                    }
                }
            },
            (error) => {
                console.error(error);
            }
        );
    }

    async handleRouteEvents() {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                const title = this.getTitle(
                    this.router.routerState,
                    this.router.routerState.root
                ).join('-');
                this.googleAnalyticsService.sendEvent(
                    'page_view',
                    title,
                    event.urlAfterRedirects,
                    this.document.location.href
                );
                setTimeout(() => {
                    this.checkCookies();
                }, 1000);
            }
        });
    }

    getTitle(state: RouterState, parent: ActivatedRoute): string[] {
        const data = [];
        if (parent && parent.snapshot.data && parent.snapshot.data['title']) {
            data.push(parent.snapshot.data['title']);
        }
        if (state && parent && parent.firstChild) {
            data.push(...this.getTitle(state, parent.firstChild));
        }
        return data;
    }

    callMenu() {
        this.secureStorage.get(Contract.user).subscribe(
            (user) => {
                this.modulos = JSON.parse(user);
                let rolname = this.modulos.ObjectValue[0].Accounts[0].RolName;
                this.authService.setUserData(this.modulos);
                environment.rol =
                    this.modulos.ObjectValue[0].Accounts[0].TacName;
                if (environment.rol !== 'Express') {
                    if (rolname !== undefined) {
                        environment.rol = rolname;
                    }
                }

                this.menuService.addMenu(this.modulos.ObjectValue[0].Modules);
            },
            (error: any) => {
                this.secureStorage.remove([Contract.user]).subscribe();
            }
        );
    }

    getRouteActive() {
        this.secureStorage.get(Contract.routeActive).subscribe((route) => {
            const url = this.router.url.split('/');

            let routeActive = JSON.parse(route);
            if (routeActive && routeActive != null) {
                this.router.navigate([routeActive]);
            }
        });
    }

    async checkCookies() {
        const url = this.router.url.split('/');
        let filterUrl = url?.filter(
            (item) => item == 'compra' || item == 'inicio-sesion-interno'
        );
        if (filterUrl?.length > 0) {
            return;
        }
        let userString: any;
        let user: any;
        let userCredential = this.encryptedCookieStorageService.get(
            Contract.userCredential
        );
        let passwordCredential = this.encryptedCookieStorageService.get(
            Contract.passwordCredential
        );
        let countryCredential = this.encryptedCookieStorageService.get(
            Contract.countryCredential
        );

        try {
            userString = await this.secureStorage
                .get(Contract.user)
                .toPromise();
        } catch (error) {}

        if (userString) {
            user = JSON.parse(userString);
        }
        if (!user) {
            if (userCredential && passwordCredential && countryCredential) {
                this.user = userCredential;
                this.password = passwordCredential;
                this.country = countryCredential;
                setTimeout(() => {
                    this.loginUser(this.user, this.password, countryCredential);
                }, 100);
            }
        } else {
            if (user.Params.UserName && userCredential) {
                if (user.Params.UserName != userCredential) {
                    this.user = userCredential;
                    this.password = passwordCredential;
                    this.country = countryCredential;
                    this.cleanLocalStorage = true;
                    this.message = `¿ Desea iniciar sesión con este usuario: ${userCredential} ?`;
                    setTimeout(() => {
                        this.modalForCookies?.Open();
                    }, 100);
                }
            }
        }
    }

    loginUser(user: any, password: any, country: any) {
        let body = {
            path: 'MarketPlace/SetLogin',
            data: {
                Method: 'SetLogin',
                Params: {
                    UserName: user,
                    Password: password,
                    IdCountry: country,
                },
            },
        };

        this.authentication.login(body).subscribe(
            async (response: any) => {
                if (
                    response?.ObjectValue?.Accounts[0]?.TacName == 'Empresarial'
                ) {
                    response.ObjectValue.Accounts[0].TacName = 'Individual';
                }
                environment.rol = '';
                this.responseLogin = { ...response.ObjectValue };
                response.ObjectValue = [];
                response.ObjectValue.push(this.responseLogin);

                if (
                    response.ObjectValue[0].Accounts[0].TacName == 'Empresarial'
                ) {
                    environment.rol = 'Individual';
                } else {
                    environment.rol =
                        response.ObjectValue[0].Accounts[0].TacName;
                }

                if (environment.rol == 'Express') {
                    environment.expressUserData = [];
                    environment.expressUserData.push(
                        response.ObjectValue[0].Profile[1]
                    );
                }
                if (environment.rol == 'Individual') {
                    this.secureStorage
                        .save(Contract.updateVersion, false)
                        .subscribe();

                    this.secureStorage
                        .save(Contract.user, JSON.stringify(response))
                        .subscribe();
                    await this.saveLocalCartService.saveLocalCart();
                    this.authService.updateVersion();
                    //Limpieza de cookie de login
                    this.encryptedCookieStorageService.remove(
                        Contract.userCredential,
                        '.forzadelivery.com'
                    );
                    this.encryptedCookieStorageService.remove(
                        Contract.passwordCredential,
                        '.forzadelivery.com'
                    );
                    this.encryptedCookieStorageService.remove(
                        Contract.countryCredential,
                        '.forzadelivery.com'
                    );
                    //window.location.reload();
                    window.location.href = '/' + country;
                }
            },
            (error) => {
                // if (error.IdResult == 400) {
                //     this.correoConfirm(user);
                // }
                // this.sweetAlert.errorCriticalAlert(
                //     'Error al iniciar sesión',
                //     error.Message ? error.Message : error
                // );
            }
        );
    }

    cancel() {
        this.encryptedCookieStorageService.remove(
            Contract.userCredential,
            '.forzadelivery.com'
        );
        this.encryptedCookieStorageService.remove(
            Contract.passwordCredential,
            '.forzadelivery.com'
        );
        this.encryptedCookieStorageService.remove(
            Contract.countryCredential,
            '.forzadelivery.com'
        );
        this.modalForCookies?.Close();
    }

    accept() {
        if (this.cleanLocalStorage) {
            this.authService.clearLocalStorage();
        }
        this.loginUser(this.user, this.password, this.country);
        this.modalForCookies?.Close();
    }
}
