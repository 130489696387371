import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { witheText } from 'src/utility/functions/validators';
import { ConnectionApiService } from 'src/utility/services/connection-api.service';
import { Contract } from 'src/utility/Storage/contract';
import { SecureSimpleStorage } from 'src/utility/Storage/secure-simple-storage';
import { SweetAlert } from 'src/utility/sweetAlert';
import { ModalComponent } from '../modal/modal.component';
import { SelectComponent } from '../select/select/select.component';
import { environment } from 'src/environments/environment';
@Component({
    selector: 'app-form-card-management',
    templateUrl: './form-card-management.component.html',
    styleUrls: ['./form-card-management.component.scss'],
})
export class FormCardManagementComponent implements OnInit {
    countrySelect: any;
    @Input() isModal: boolean = true;
    @Input() showFinish: boolean;
    listMonth = [];
    listYears = [];
    typeCardIcon: string = '';
    formCardManagement: FormGroup;
    @Output() onSaveCard: EventEmitter<any> = new EventEmitter<any>();
    @Output() sendCardAdded: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild('selectMonth') selectMonth: SelectComponent;
    @ViewChild('selectYear') selectYear: SelectComponent;
    @ViewChild('modalCVV') modalCVV: ModalComponent;
    arraySpliter = [4, 4, 4, 4];
    PREFIXES_MASTERCARD: string[] = [
        '2221',
        '2222',
        '2223',
        '2224',
        '2225',
        '2226',
        '2227',
        '2228',
        '2229',
        '223',
        '224',
        '225',
        '226',
        '227',
        '228',
        '229',
        '23',
        '24',
        '25',
        '26',
        '270',
        '271',
        '2720',
        '50',
        '51',
        '52',
        '53',
        '54',
        '55',
        '67',
    ];
    constructor(
        private secureStorage: SecureSimpleStorage,
        private api: ConnectionApiService,
        private sweet: SweetAlert,
        private changeDetector: ChangeDetectorRef
    ) {
        this.listMonth = this.getMonths();
        this.listYears = this.getYears();

        this.formCardManagement = new FormGroup({});
    }

    ngOnInit(): void {
        this.countrySelect = environment.multiCountryData[0].countrySelect;
    }

    ngAfterContentChecked(): void {
        this.changeDetector.detectChanges();
    }

    ngAfterViewInit(): void {
        this.formCardManagement.valueChanges.subscribe((value) => {
            if (this.formCardManagement.valid) {
                value = {
                    ...value,
                    ...{
                        TypeCardNumber: this.validarTipoTC(
                            this.getNumCard.replace(/\s/g, '')
                        ),
                    },
                };
                this.sendCardAdded.emit(value);
            } else {
                this.sendCardAdded.emit(false);
            }
        });
    }

    /**
     * @description Metodo que retorna los 12 meses del año en un array para un componente app-select
     * @author Randy Can
     * @date 12/07/2022
     * @returns {Array<any>}
     * @memberof FormCardManagementComponent
     */
    getMonths(): Array<any> {
        const months = [];
        let month = '';
        for (let i = 1; i <= 12; i++) {
            month = moment(String(i), 'MM').locale('es').format('MMMM');
            months.push({
                text: month.charAt(0).toUpperCase() + month.slice(1),
                value: moment(String(i), 'MM').locale('es').format('MM'),
            });
        }
        return months;
    }

    /**
     * @description metodo que retorna los 10 años a partir del actual en un array para un componente app-select
     * @author Randy Can
     * @date 12/07/2022
     * @returns {Array<any>}
     * @memberof FormCardManagementComponent
     */
    getYears(): Array<any> {
        const years = [];
        const currectYear = moment().format('YYYY');
        for (let i = 0; i <= 10; i++) {
            years.push({
                text: String(Number(currectYear) + i),
                value: moment(Number(currectYear) + i, 'YYYY').format('YY'),
            });
        }
        return years;
    }

    /**
     * @description Validacion de tipo de tarjetas
     * @author Randy Can
     * @date 27/07/2022
     * @param {string} tarjeta
     * @returns {*}
     * @memberof FormCardManagementComponent
     */
    validarTipoTC(tarjeta: string) {
        this.typeCardIcon = '';
        let tipo = '';
        if (tarjeta.substr(0, 1) === '4') {
            tipo = 'V';
            this.typeCardIcon = 'hwa-visa';
        } else if (
            tarjeta.substr(0, 2) === '34' ||
            tarjeta.substr(0, 2) === '37'
        ) {
            tipo = 'A';
            this.typeCardIcon = 'hwa-americanExpress';
        } else {
            this.PREFIXES_MASTERCARD.forEach((element) => {
                if (element.length === 4 && tarjeta.substr(0, 4) === element) {
                    tipo = 'C';
                    this.typeCardIcon = 'hwa-masterCard';
                } else if (
                    element.length === 3 &&
                    tarjeta.substr(0, 3) === element
                ) {
                    tipo = 'C';
                    this.typeCardIcon = 'hwa-masterCard';
                } else if (
                    element.length === 2 &&
                    tarjeta.substr(0, 2) === element
                ) {
                    tipo = 'C';
                    this.typeCardIcon = 'hwa-masterCard';
                }
            });
        }
        return tipo;
    }

    /**
     * @description metodo utilizado para guardar la informacion ingresada en el formulario para tarjetas de credito y debito
     * @author Randy Can
     * @date 12/07/2022
     * @memberof FormCardManagementComponent
     */
    async saveCard() {
        //validar año en curso
        if (
            this.formCardManagement.get('year').get('input').value.text ==
            moment().format('YYYY')
        ) {
            if (Number(this.getMonth) < Number(moment().format('MM'))) {
                this.sweet.errorCriticalAlert(
                    'Error al agregar tarjeta',
                    'La tarjeta ya expiró'
                );
                return;
            }
        }

        const userString = await this.secureStorage
            .get(Contract.user)
            .toPromise();
        const user = JSON.parse(userString);

        //construyendo body
        const body = {
            path: 'Payment/SetPaymentMethod',
            data: {
                Method: 'SetPaymentMethod',
                Params: {
                    AccountId: String(
                        user.ObjectValue[0].Accounts[0].IdAccount
                    ),
                    CustomerId: String(
                        user.ObjectValue[0].Accounts[0].IdCustomer
                    ),
                    CardNumber: this.getNumCard.replace(/\s/g, ''),
                    CardExpiration: this.getMonth + this.getYear,
                    SecurityCode: this.getCcv,
                    Type: this.validarTipoTC(
                        this.getNumCard.replace(/\s/g, '')
                    ),
                    Token: String(user.ObjectValue[0].Token),
                    Holder: this.getNameCard.trim(),
                    CountryId: this.countrySelect.value,
                },
            },
        };

        try {
            //realizando peticion
            const response = await this.api.postData(body).toPromise();

            //verifica que StatusCode exista
            if (!response.StatusCode) {
                this.sweet.errorCriticalAlert(
                    'Error al agregar tarjeta',
                    response.Message ? response.Message : response
                );
                return;
            }

            //cuando StatusCode sea igual a 400
            if (response.StatusCode === 400) {
                this.sweet.errorCriticalAlert(
                    'Tarjeta duplicada',
                    'La tarjeta ya existe'
                );
                return;
            }

            //cuando StatusCode diferente a 200
            if (response.StatusCode !== 200) {
                this.sweet.errorCriticalAlert(
                    'Error al agregar tarjeta',
                    response.Message ? response.Message : response
                );
                return;
            }

            //cuando StatusCode sea 200
            this.sweet.successCriticalAlert(
                'Administración de tarjetas',
                'Registro Correcto'
            );
            this.formCardManagement.reset();
            this.selectYear.markInvalidReset();
            this.selectMonth.markInvalidReset();
            this.onSaveCard.emit(response);
            return;
        } catch (error) {
            // error en flujo dentro de try se ejecuta lo siguiente
            this.sweet.errorCriticalAlert(
                'Error al agregar tarjeta',
                error.Message ? error.Message : error
            );
        }
    }

    addForm(name: string, formulario: FormGroup) {
        this.formCardManagement.removeControl(name);
        if (name == 'numCard') {
            formulario
                .get('input')
                .setValidators([
                    Validators.required,
                    Validators.pattern(
                        '[0-9]{4} [0-9]{4} [0-9]{4} ([0-9]{3}|[0-9]{4})'
                    ),
                ]);
            formulario.get('input').valueChanges.subscribe((x) => {
                if (x) {
                    this.validarTipoTC(x.replace(/\s/g, ''));
                } else {
                    this.typeCardIcon = '';
                }
            });
        } else if (name == 'nameCard') {
            formulario
                .get('input')
                .setValidators([Validators.required, witheText]);
        } else if (name == 'ccv') {
            formulario
                .get('input')
                .setValidators([
                    Validators.required,
                    Validators.pattern(
                        /^(?!000$)(?!999$)(?!0000$)(?!9999$)[0-9]{3,4}$/
                    ),
                ]);
        }
        this.formCardManagement.addControl(name, formulario);
    }

    onClickQuestion() {
        this.modalCVV.Open();
    }

    /**
     * @description metodos get para formulario formCardManagement
     * @author Randy Can
     * @date 12/07/2022
     * @readonly
     * @type {string}
     * @memberof FormCardManagementComponent
     */
    public get getNumCard(): string {
        return this.formCardManagement.get('numCard').get('input').value;
    }
    public get getNameCard(): string {
        return this.formCardManagement.get('nameCard').get('input').value;
    }
    public get getMonth(): string {
        return this.formCardManagement.get('month').get('input').value.value;
    }
    public get getYear(): string {
        return this.formCardManagement.get('year').get('input').value.value;
    }
    public get getCcv(): string {
        return this.formCardManagement.get('ccv').get('input').value;
    }

    public get disableSave(): boolean {
        return this.formCardManagement.invalid;
    }
}
