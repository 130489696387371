import { Injectable } from '@angular/core';
import { SecureSimpleStorage } from './Storage/secure-simple-storage';
import { environment } from 'src/environments/environment';
import { Contract } from './Storage/contract';
import { EncryptedCookieStorageService } from './services/marketplace/encrypted-cookie-storage.service';

@Injectable({
    providedIn: 'root',
})
export class GetCountriesService {
    constructor(
        private secureStorage: SecureSimpleStorage,
        private encryptedCookieStorageService: EncryptedCookieStorageService
    ) {}

    async saveData() {
        environment.multiCountryData = [];
        environment.TelemercadeoCountryData = [];
        let userRol = environment.rol;
        let countrySelect: {
            countryName: any;
            value: any;
            icon: any;
            prefixPhone: any;
            currency: any;
        };
        let taxType = '';
        this.secureStorage.get(Contract.user).subscribe(
            (data) => {
                let dataUser = JSON.parse(data);
                let countryUser =
                    userRol == 'Ventas telemercadeo'
                        ? dataUser.ObjectValue[0].Profile[1].IdCountry
                        : dataUser.ObjectValue[0].Profile[0].IdCountry;
                if (countryUser == 'GT') {
                    countrySelect = {
                        countryName: 'Guatemala',
                        icon: 'icon-gt',
                        value: 'GT',
                        prefixPhone: '+502',
                        currency: 'GTQ',
                    };
                } else {
                    countrySelect = {
                        countryName: 'Honduras',
                        icon: 'icon-hn',
                        value: 'HN',
                        prefixPhone: '+504',
                        currency: 'HNL',
                    };
                }
                taxType = countrySelect.value == 'GT' ? 'NIT' : 'RTN';
                environment.multiCountryData.push({
                    countrySelect,
                    taxType,
                    idCurrency: dataUser.ObjectValue[0].Profile[0].IdCurrency,
                });
                if (userRol == 'Ventas telemercadeo') {
                    environment.TelemercadeoCountryData.push({
                        countrySelect,
                        taxType,
                    });
                }
            },
            (error) => {
                let country = this.encryptedCookieStorageService.get(
                    Contract.countrySelected
                );

                if (country !== null) {
                    countrySelect = JSON.parse(country);
                    taxType = countrySelect.value == 'GT' ? 'NIT' : 'RTN';
                }

                environment.multiCountryData.push({
                    countrySelect,
                    taxType,
                });
            }
        );
    }

    getCountries() {
        let countries: any = [
            {
                text: 'Guatemala',
                countryName: 'Guatemala',
                icon: 'icon-gt',
                value: 'GT',
                prefixPhone: '+502',
                currency: 'GTQ',
            },
            {
                text: 'Honduras',
                countryName: 'Honduras',
                icon: 'icon-hn',
                value: 'HN',
                prefixPhone: '+504',
                currency: 'HNL',
            },
        ];
        return countries;
    }
}
